body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #3A5F9E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  padding: 10px;
}

.active {
  text-decoration: none;
  color:#3A5F9E!important;
}

.not-active {
  text-decoration: none;
  padding-right: 12px;
  color:#000;
  /* font-size: calc(100% + 0.7vw - 0.7vh); */
  
}

.btn.btn-custom {
  background: #256095;
  color: #ffffff;
  width: 100%;
  height: 59px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: none;
  /* margin: 5px 40px; */
}

.btn.btn-dsbl {
  background: #737f8a;
  color: #ffffff;
  width: 100%;
  height: 59px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: none;
  /* margin: 5px 40px; */
}

.btn.btn-custom:hover, .btn.btn-custom:focus {
  color: #fff;
  background: #00436C!important;
  border-color: #256095; /*set the color you want here*/
}

.btn.btn-dsbl:hover, .btn.btn-custom:focus {
  color: #fff;
  background: #5c758c!important;
  border-color: #5c758c; /*set the color you want here*/
}

.btn.btn-custom:active {
  background: #00436C!important;
  color:#fff!important;
}

.btn.btn-custom:active {
  background: #53718d!important;
  color:#fff!important;
}

.card.card-custom {
  /* width: 42rem; */
  min-width: 376px!important;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px;
}

.card-custom-right {
  /* width: 42rem; */
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 0px;
  background-image: url('Components/Images/vectors.svg');
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;

}

.card-confirm-right {
  /* width: 42rem; */
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 0px;
}

.row-custom {
  /* width: 42rem; */
  min-width: 376px!important;
}

.righttips {
  /* height: 20vh; */
  text-align: center;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}

.card.card-suggest {
  /* width: 38rem; */
  min-width: 376px!important;
  min-height: 502px!important;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px;
}

.suggest-text {
  color: #8A8A8A;
  padding-bottom: 0px;
  margin-top: 7px!important;
  
}

.suggest-text-index-form {
  color: #272727;
  padding-bottom: 0px;
  margin-top: -10px!important;
  padding-top: 18px;
}


.suggest-text-bottom {
  color: #8A8A8A;
  padding-bottom: 10px;
  padding-top: -20px!important;
}

.gray-text {
  color: #8A8A8A;
  padding-bottom: 0px;
  padding-top: 7px;
  font-size: smaller;
}

.gray-text-fssp-first {
  color: #8A8A8A;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: smaller;
}

.gray-text-fssp {
  color: #8A8A8A;
  padding-bottom: 0px;
  padding-top: 10px;
  font-size: smaller;
}

.black-text-fssp {
  color: #333333;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: smaller;
}

.fssp-subtitle {
  color: #333333;
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 7px;
  font-size: medium;
}

.fssp-subtitle-first {
  color: #333333;
  padding-bottom: 0px;
  padding-top: 7px;
  padding-left: 7px;
  font-size: medium;
}

.blue-text-skidka {
  color: #256095;
  padding-bottom: 0px;
  font-weight: bold;
}

.gray-text-skidka {
  color: #333333;
  padding-bottom: 0px;
  font-weight:normal;
  font-size:80%;
}


.finesSumma {
  display: inline-block;

}

.gray-underline-text {
  color: #8A8A8A;
  padding-bottom: 0px;
  font-weight:normal;
  font-size:80%;
  text-decoration: line-through;
}

.summa-text {
  font-size:larger;
  margin-right: 7px;
}

.blue-text {
  color: #3A5F9E;
  padding-bottom: 8px;
  padding-top: 15px;
  padding-left: 15px!important;
  font-weight:bold!important;
  
}

.bold-text {
  color: #000;
  padding-bottom: 8px;
  font-weight:bold!important;

}

.pay-recive-text {
  color: #000;
  padding-bottom: 8px;
  padding-left: 10px;
  max-width: 20rem!important;
}

.pay-submit-gray-text {
  color: #8A8A8A;
  padding-bottom: 8px;
  padding-left: 10px;
}

.bold-text-right {
  color: #000;
  padding-bottom: 8px;
  font-weight:bold!important;
  text-align: right;
}

.regular-text-right {
  color: #000;
  padding-bottom: 8px;
  text-align: right;
}
.taxsumma {
  font-size:large;
  font-weight:bold!important;
  text-align: center;
}

#p2 {
  padding : 0;
  margin : 0;
  line-height : 30px;
}


.indexform {
  height: 60px;
  padding-bottom: -10px!important;
}

.indexformfield {
  padding-bottom: -10px!important;
}

.form-label {
  margin-bottom: 0.1rem!important;
}

.ollist {
  padding-top: 15px!important;
}

.olnum {
  font-weight: bold;
  font-size: large;
}

.card-tips {
  margin-bottom: 20px;
}

.indexform-gosnomer {
  height: 60px;
  width: 83%!important;
}

.indexform-region {
  height: 60px;
  width: 40%!important;
  margin-left: 50px!important;
}

.full-gosnomer {
  /* width: 100%!important; */
  /* position: relative!important; */

}

.gosnomer {
  margin-right: -3.5rem!important;
  padding-right: 0px!important;
  padding-bottom: 19px!important;
}

.gosnomer-borders {
  /* border-color: 929292!important; */
  border-width: 1px!important;
  border-end-end-radius: 0px!important;
  border-start-end-radius: 0px!important;
  border-start-start-radius: 7px!important;
  border-end-start-radius: 7px!important;
  text-transform: uppercase;
  
  padding-left: 12px!important;
  width: 100%!important;
  height: 60px;
  padding: 6px 12px;
  line-height: 1.428571429;
  background-color: #ffffff;
  border: 1px solid #777888;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
          transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}



gosnomerfield::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: red!important;
}


.region-borders {
  /* border-color: 929292!important; */
  border-width: 1px!important;
  border-end-end-radius: 7px!important;
  border-start-end-radius: 7px!important;
  border-start-start-radius: 0px!important;
  border-end-start-radius: 0px!important;

  width: 100%;
  height: 60px;
  padding: 6px 12px;
  line-height: 1.428571429;
  background-color: #ffffff;
  border: 1px solid #777888;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
          transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.fields-borders {
  width: 100%!important;
  height: 60px;
  padding: 6px 12px;
  line-height: 1.428571429;
  background-color: #ffffff;
  border: 1px solid #777888;
  border-end-end-radius: 7px!important;
  border-start-end-radius: 7px!important;
  border-start-start-radius: 7px!important;
  border-end-start-radius: 7px!important;

  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
          transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.suggest-tips  {
  color: #a14e2e!important;
  font-size: 85%;
}

.region {
  margin-left: -4rem!important;
  padding-left: 0px!important;
}

.customlink {
  text-decoration:none;
  color:#3A5F9E;
}

.footerVisible {
  min-width: 320px!important;
  position: relative;
}

.link-button {
  text-decoration:none!important;
  color:#3A5F9E!important;
  margin-left: 0px!important;
  padding-left: 0px!important;
  margin-bottom: 0px!important;
  padding-bottom: 0px!important;
}

.qclass {
  font-weight: 600;
}

.aclass {
  color:#333333;
}



.customlinkHead {
  text-decoration:none;
  color:#333333;
  margin-right: 15px!important;
}


.suggest-img {
  width: 18rem!important;
  background: #F5F7FC;
  
}


.payrecive-img {
  width: 20rem!important;
  background: #F5F7FC;
}

.suggestpasp-img {
  width: 24rem!important;
  background: #F5F7FC;
}

.suggester {
  padding-left: 0px!important;
  padding-right: 0px!important;
  display: flex; 
  align-items: center;
}

.suggestpassport-img {
  width: 6rem;
  
}

.camera-img {
  width: 11rem;
  padding-top: 40px!important;
  padding-bottom: 40px!important;
  padding-left: 15px!important;
  padding-right: 20px!important;
}

.grs-img {
  padding-top: 20px!important;
  padding-bottom: 20px!important;
}


.sts-img {
  padding-top: 20px!important;
  padding-bottom: 20px!important;
}


.tips {
  width: 12rem;
  padding-top: 20px!important;
  padding-bottom: 20px!important;
  padding-left: 10px!important;
}

.card.suggest-field {
  background: #F5F7FC;
  border-color: #F5F7FC;
 
}

.suggest-field {
  padding-top: 0px!important;
}


.suggest-row {
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0rem;
  padding-bottom: 1rem;
}

.card.card-info-index {
  /* width: 79rem; */
  min-width: 376px!important;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px;
  margin-top: 20px;
}
/* Firstblock */
.card.card-info-firstindex {
  /* width: 79rem; */
  min-width: 376px!important;
  max-height: 500px!important;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #D6E1E8;
  padding: 10px;
  margin-top: 20px;
}

.leftindextext {
  max-width: 80%!important;
}

.rightindextext {
  max-width: 50%!important;
}

.bigdiscount {
  font-weight: 600;
  font-size: 140px;
  color: #00436C;
  margin-left: 5%!important;
}

.discounttips {
  font-size: 16px;
}

.discounttips2 {
  font-size: 16px;
  margin-left: 5%!important;
}

.centerindextext {
  max-width: 25%!important;
  max-height: 500px!important;
  position: relative;
  margin-top: -30px!important;
  margin-bottom: -30px!important;
}

.centerindextextsm {
  max-width: 20%!important;
  /* height: 370px!important; */
  position: relative;
  margin-top: -30px!important;
  margin-bottom: -30px!important;
  margin-right: 150px!important;
}


/* Secondblock */
.card.card-info-secondindex {
  /* width: 79rem; */
  max-height: 500px!important;
  min-width: 376px!important;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #D6E1E8;
  padding: 10px;
  margin-top: 20px;
  background-image: url('Components/Images/secondbg.svg');
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .card.card-info-secondindex {
      background-image: url('Components/Images/secondbgsm.svg');
      
  }
}

.leftindextexttmaps {
  max-width: 70%!important;
}

.rightindextextmaps {
  
}

.photoindex-img {
  float: right;
  padding-top: 20px!important;
  padding-bottom: 20px!important;
  margin-right: 20px!important;
}



/* Thirdblock */
.card.card-info-thirdblock {
  /* width: 79rem; */
  min-width: 376px!important;
  max-height: 500px!important;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #256095;
  padding: 10px;
  margin-top: 20px;
}

.leftindextextthird {
  max-width: 80%!important;
}

.h2classthird {
  font-size: 33px!important;
  color: #fff;
}

.discounttipsthird {
  font-size: 16px;
  color: #fff;
}

.centerindextextthird {
  max-width: 20%!important;
  max-height: 500px!important;
  position: relative;
  margin-top: 15px!important;
  margin-right: -10%!important;
}

.centerthird {
  font-size: 38px!important;
  color: #fff;
  padding-top: 1px!important;
  padding-bottom: 1px!important;
  margin-top: 1px!important;
  margin-bottom: 1px!important;
}



.logo-img {
  float: right;
  margin-right: -10px!important;
  margin-bottom: 15px!important;
}


/* Fourthblock */

.leftfourthindex {
  margin-bottom: 50px;
  max-width: 95%!important;
}

.card.card-info-fourthindex {
  /* width: 79rem; */
  min-width: 376px!important;
  max-height: 650px!important;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: #D6E1E8;
  padding: 10px;
  margin-top: 20px;
  background-image: url('Components/Images/cardsimg.png');
  background-position: bottom right;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .card.card-info-fourthindex {
      background: #D6E1E8 !important;
  }
}

@media (max-width: 768px) {
  .leftfourthindex {
    padding-bottom: 100px!important;
  }
}

.finespromotips {
   max-width: 70px;

}

.tipsfineslogo {
  max-width: 100px!important;
  margin-top: 25px!important;
}

.tipsfineslogo1 {
  max-width: 100px!important;
  margin-top: 25px!important;
}

.tipsfineslogo2 {
  max-width: 100px!important;
  margin-top: 10px!important;
}

.tipsfineslogo3 {
  max-width: 100px!important;
  margin-top: 5px!important;
}

.tipsfineslogo4 {
  max-width: 100px!important;
  margin-top: 25px!important;
}

.tipsfineshead {
  font-weight: bold;
  margin-top: 15px;
  padding-bottom: 0px;
  
  
}

.colTipsfineshead {
  width: 70%!important;

}

.tipstxt {
  margin-top: 7px;
  font-size: 14px;
  color: #014067;
}




.mobilelogo-img {
  width: 6rem!important;
  background: #F3F3F2;
}

.storelogo-img {
  width: 9rem!important;
  padding-top: 5px;
  padding-bottom: 20px;
  padding-right: 10px;
}

.banklogo-img {
  width: 196px!important;
}

.icon-result {
  height: 14px!important;
  padding-left: 10px;
  padding-right: 4px;
}

.icon-fines-no-found {
  height: 290px!important;
  /* padding-left: 10px;
  padding-right: 4px; */
  display: flex; 
  align-items: center;
}

.navbar-brand-logo  {
  width: 310px!important;
}

.image-humbnail {
  max-height: 300px!important;
  max-width: 200px!important;
  padding-right: 3px!important;
  padding-bottom: 3px!important;
  /* display: flex; 
  align-items: center; */
}

.h2class {
  font-size: 33px!important;
}


h1 {
  font-size:xx-large!important;
}

.pay-btn {
  background: #3A5F9E;
  color: #ffffff;
  min-width: 103px!important;
  height: 49px!important;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 7px!important;
  text-transform: none;
  font-weight:bold!important;
  /* margin: 5px 40px; */
}

.payall-btn {
  background: #3A5F9E;
  color: #ffffff;
  min-width: 103px!important;
  height: 49px!important;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-transform: none;
  font-weight:bold!important;
  /* margin: 5px 40px; */
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.confirm-container {
  padding-top: 20px;

  
}

.footer-custom {
  background-color: #F5F7FC;
  color: #000;
  /* background: url(./bglogo.svg); */
  background-repeat: no-repeat;
  background-position: right;
  min-width: 1003px!important;
  min-height: 1003px!important;
}



.highlight-container, .highlight {
  color:#fff;
  position: relative;
  padding-left: 2px;
}

.highlight-container {
  display: inline-block;
}
.highlight-container:before {
  
  content: " ";
  display: block;
  height: 110%;
  width: 102%;
  margin-left: 1px;
  margin-right: 1px;
  position: absolute;
  background: #256095;
  top: -1px;
  left: -1px;
  padding: 10px 3px 3px 10px;
}
							

/* 
.card-num, .rc {
  border-color: #cacaca!important;
  border-width: 1px!important;
  border-radius: 4px;
  height: 44px;
  width: 270px!important;
}

.card-cvc, .card-exp {
  border-color: #cacaca!important;
  border-width: 1px!important;
  border-radius: 4px;
  height: 44px;
  width: 100px!important;
}

.input-label {
  font-size: 14px;
  font-weight: normal;
}

.zf-hint, .hint {
  font-size: 14px;
  font-weight: normal;
  color: #333333;
} */






/* html.widget #content { color: #fff; background: rgba(0, 0, 0, 0) url("https://www.moneta.ru/info/public/w/partnership/cardbg3.gif") repeat scroll 0 0; }
html.widget #tab-ps-selector { display: none; }
html.widget .verified-by-systems { display: none; }
html.widget .page-wrapper form input[type="text"], html.widget .page-wrapper form input[type="password"] { color: #000; height: 32px !important; }
html.widget form select { height: 32px !important; color: #000; padding: 4px !important; }
html.widget .tab-container { padding: 28px 17px; }
.h-buttons { position: absolute; margin-top: 21px; color: #000; }
.input-label { color: #fff; }
.field-cardHolder { display: none; }
.field-ownerLogin { display: none; }
.field-description { display: none; }
.field-totalAmount { position: absolute; font-weight: bold; margin-left: 113px; margin-top: 187px; color: #000; }
.field-totalAmount .input-label  { display: none; }
.field-cardCVV2 dd { display: flex; align-items: center; }
.field-cardExpiration dd { display: flex; align-items: center; }
#cvc_hint { display: none; }
#additionalParameters_cardNumber { background: #aae3c9; box-shadow: none; }
#additionalParameters_cardHolder { border: none; background: #aae3c9; box-shadow: none; }
#additionalParameters_cardCVV2 { background: #aae3c9; box-shadow: none; }
#additionalParameters_ownerLogin { border: none; background: none; box-shadow: none; }
#cardExpirationMonth { background: #aae3c9; box-shadow: none; margin-right: 5px; font-size: 15px; }
#cardExpirationYear { background: #aae3c9; box-shadow: none; margin-left: 5px; font-size: 15px; }
#cardtype, .card-type { margin: 90px 77px; }
.oauth-splitter { display: none; }
.oauth { display: none; } */
							